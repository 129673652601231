@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans&family=Roboto+Slab&display=swap");

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 50px;
  z-index: 1000;
  color: white;
  /* font-family: "Roboto Slab", serif; */
  font-family: "Alegreya Sans", sans-serif;
}

.cover a {
  color: #f3f3f3;
  text-decoration: none;
}
